<div class="container">

	<nav class="navbar navbar-expand-lg">
		<!-- LEFT -->
		<div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
			<ul class="navbar-nav mr-auto">
				<li
					*ngIf="showElement"
					[routerLinkActive]="['active']"
					class="nav-item">
					<a
						class="nav-link"
						[ngClass]="{'disabled': !selectedLavoro}"
						[routerLink]="['/reserved/preventivi']"
						title="{{'header.preventivi' | translate}}">
						{{"header.preventivi" | translate}}
					</a>
				</li>
				<li
					*ngIf="showElement"
					[routerLinkActive]="['active']"
					class="nav-item">
					<a
						class="nav-link"
						[ngClass]="{'disabled': !selectedLavoro}"
						[routerLink]="['/reserved/questionari']"
						title="{{'header.questionari' | translate}}">
						{{"header.questionari" | translate}}
					</a>
				</li>
				<li
					*ngIf="showElement"
					[routerLinkActive]="['active']"
					class="nav-item">
					<a
						class="nav-link"
						[ngClass]="{'disabled': !selectedLavoro}"
						[routerLink]="['/reserved/guestlist']"
						title="{{'header.guestlist' | translate}}">
						{{"header.guestlist" | translate}}
					</a>
				</li>
				<li
					*ngIf="showElement"
					[routerLinkActive]="['active']"
					class="nav-item">
					<a
						class="nav-link"
						[ngClass]="{'disabled': !selectedLavoro}"
						[routerLink]="['/reserved/budget']"
						title="{{'header.sistemazioni' | translate}}">
						{{"header.sistemazioni" | translate}}
					</a>
				</li>
				<li
					*ngIf="showElement"
					[routerLinkActive]="['active']"
					class="nav-item">
					<a
						class="nav-link"
						[ngClass]="{'disabled': !selectedLavoro}"
						[routerLink]="['/reserved/budget']"
						title="{{'header.budget' | translate}}">
						{{"header.budget" | translate}}
					</a>
				</li>
				<li
					*ngIf="showElement"
					[routerLinkActive]="['active']"
					class="nav-item">
					<a
						class="nav-link"
						[ngClass]="{'disabled': !selectedLavoro}"
						[routerLink]="['/reserved/pagamenti']"
						title="{{'header.pagamenti' | translate}}">
						{{"header.pagamenti" | translate}}
					</a>
				</li>
				<li
					*ngIf="showElement"
					[routerLinkActive]="['active']"
					class="nav-item">
					<a
						class="nav-link"
						[ngClass]="{'disabled': !selectedLavoro}"
						[routerLink]="['/reserved/allegati']"
						title="{{'header.allegati' | translate}}">
						{{"header.allegati" | translate}}
					</a>
				</li>

				<li
					*ngIf="showElement"
					[routerLinkActive]="['active']"
					class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/reserved/chat']">
						<i
							aria-hidden="true"
							class="fa fa-comment"></i>
						<ng-template [ngIf]="notseencount">({{notseencount}})</ng-template>
					</a>
				</li>
			</ul>
		</div>
		<!-- CENTER -->
		<div class="mx-auto order-0">
			<a
				class="navbar-brand"
				[routerLink]="['/reserved/dashboard']"
				title="{{'header.dashboard' | translate}}">
				<img
					class="logo"
					src="/assets/images/loghi/sposiamovi.png">
			</a>
			<button
				class="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation">
				<i class="fas fa-bars"></i>
			</button>
		</div>
		<!-- RIGHT -->
		<div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
			<ul class="navbar-nav ml-auto">
				<li
					[routerLinkActive]="['active']"
					class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/reserved/profilo']"
						title="{{'header.profilo' | translate}}"
						[fragment]="'dati-personali'">
						<i class="fas fa-user"></i>
					</a>
				</li>
				<li
					[routerLinkActive]="['active']"
					class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/logout']"
						title="{{'header.logout' | translate}}">
						<i class="fas fa-sign-out-alt"></i>
					</a>
				</li>
			</ul>
		</div>
	</nav>

	<div
		*ngIf="selectedLavoro"
		class="mt-1 select-lavoro text-center">
		<!-- Lavoro -->
		<div class="form-group col-md-12">
			<small class="mr-3">{{'labels.staigestendo' | translate}}:</small>
			<label>
				{{selectedLavoro?.evento + ' - ' + selectedLavoro?.referente}}
			</label>
			<a
				class="btn btn-primary btn-sm"
				(click)="openSelectLavoroModal()"
				[ngClass]="{'hide': lavoriList.length<=1}">
				{{'cambia' | translate}}
				<i class="fas fa-chevron-circle-down"></i>
			</a>
		</div>
	</div>

	<div
		*ngIf="selectedCliente && selectedCliente.id"
		class="pb-1 text-center">
		<!-- cliente -->
		<div class="form-group col-md-12">
			<h2 class="bride-and-groom-name">
				{{selectedCliente.nome}} {{selectedCliente.cognome}}
				<ng-container *ngIf="selectedCliente.nome2">
					& {{selectedCliente.nome2}} {{selectedCliente.cognome2}}
				</ng-container>
			</h2>
		</div>
	</div>
</div>
