import { Component } from '@angular/core';
import { SharedDataService } from 'elbuild-ui-lib-core';
import { LavoroClienteService } from 'jde-ui-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'jde-customer-ui';

  constructor(private lavoroClienteService: LavoroClienteService,
    private sharedDataService: SharedDataService) {
    this.lavoroClienteService.getLavoroDefault().subscribe(res => {
      // console.log('default lavoro', res);
      this.sharedDataService.addCommonData('currentlavoro', res)
    });
  }
}
