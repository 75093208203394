import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, Page, SharedDataService } from 'elbuild-ui-lib-core';
import { LavoroCliente, LavoroClienteService, StatoTodo, Todo, TodoClienteService } from 'jde-ui-lib';
import { forkJoin } from 'rxjs';
import { LocalSharedService } from 'src/app/shared/utils/local-shared.service';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {

  pendinglist: Todo[];
  pendingcount: number;
  expiredcount: number;

  loading: boolean = true;

  lavoro: LavoroCliente;

  now: number = new Date().getTime();

  constructor(
    private checklistService: TodoClienteService,
    private lavoroService: LavoroClienteService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router,
    private sharedDataService: SharedDataService,
    private localSharedService: LocalSharedService
  ) {}

  ngOnInit(): void {
    this.sharedDataService.currentCommondata.subscribe(res => {
      if (res['currentlavoro']) {
        this.lavoro = res['currentlavoro'];
        if (this.lavoro) {
          this.load();
        }
      } else {
        this.lavoroService.getLavoroDefault().subscribe((data: LavoroCliente) => {
          this.lavoro = data;
          console.log(this.lavoro)
          this.load();
        })
      }
    })

    this.localSharedService.lavoroChangeEmitter.subscribe((value: LavoroCliente) => {
      if (value) {
        this.lavoro = value;
        this.load();
      }
    });
  }

  load(): void {
    this.loading = true;

    const searchpendingreq = this.checklistService.searchTodos(this.lavoro.id, StatoTodo.PENDING, new Page(100));
    const countpendingreq = this.checklistService.countTodos(this.lavoro.id, StatoTodo.PENDING);
    const countexpiredreq = this.checklistService.countTodos(this.lavoro.id, StatoTodo.EXPIRED);

    forkJoin([searchpendingreq, countpendingreq, countexpiredreq]).subscribe((results: any) => {
      this.pendinglist = results[0];
      this.pendingcount = results[1].ct;
      this.expiredcount = results[2].ct;
      console.log(results);
      this.loading = false;
    }, ({ error }) => {
      this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
      this.loading = false;
    });
  }

  toggleTodo(todo: Todo): void {
    this.checklistService.toggle(todo).subscribe((data: Todo) => {
      this.load();
    }, ({ error }) => {
      this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
      this.loading = false;
    });
  }

  navigateToChecklist() {
    this.router.navigateByUrl('/reserved/checklist');
  }

}
