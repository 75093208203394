<form
	#editForm="ngForm"
	[formReset]="editForm"
	(ngSubmit)="editForm.valid && submit()">
	<div class="modal-header">
		<h4 class="modal-title">{{'labels.sceglilavoro' | translate}}</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="dismiss()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		class="modal-body"
		ngbAutofocus>

		<div class="row">
			<div class="col-sm-12">
				<div class="row">
					<!-- fname -->
					<div class="form-group col-md-12">
						<select
							#lavoro="ngModel"
							[(ngModel)]="selectedLavoro"
							class="form-control custom-select"
							id="lavoro"
							name="lavoro"
							required>

							<option
								*ngFor="let item of lavoriList; index as i"
								[selected]="item.id == selectedLavoro"
								[ngValue]="item.id">
								{{item.evento + ' - ' + item.referente}}
							</option>
						</select>
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && lavoro.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
	<div class="modal-footer">

		<button
			type="button"
			class="btn btn-outlined-primary"
			(click)="dismiss()">
			{{'labels.cancel' | translate}}
		</button>
		<button
			type="submit"
			class="btn btn-primary">
			{{'labels.apply' | translate}}
		</button>
	</div>
</form>
