// my-service.service.ts
import { Injectable, EventEmitter } from '@angular/core';
import { LavoroCliente } from 'jde-ui-lib';

@Injectable({
  providedIn: 'root'
})
export class LocalSharedService {
  public lavoroChangeEmitter = new EventEmitter<LavoroCliente>();

  emitLavoroValue(lavoro: LavoroCliente) {
    this.lavoroChangeEmitter.emit(lavoro);
  }
}
