<header>
	<app-header></app-header>
</header>
<section class="main">
	<div class="container-fluid">
		<div class="row">
			<div
				[ngClass]="{'col-md-12':!showChecklist, 'col-md-9':showChecklist}"
				class="col-md-9">
				<router-outlet></router-outlet>
			</div>
			<div
				*ngIf="showChecklist"
				class="col-md-3">
				<div class="card checklist">
					<div class>
						<app-checklist></app-checklist>

					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<footer class="footer">
	<div class="container">
		<app-footer></app-footer>

	</div>
</footer>
