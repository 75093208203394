import { Component, OnInit } from '@angular/core';
import { Login, AuthService, SharedDataService, MessageService, AuthConfig, Staff } from 'elbuild-ui-lib-core';
import { Router } from '@angular/router';
import { User } from 'jde-ui-lib';
import { plainToClass } from 'class-transformer';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	logindata: Login = new Login();
	message: string;
	loggedUser: User;
	email: string;

	selectedcompany: string;
	logoimage = 'assets/images/logo-dark.png';

	constructor(private authService: AuthService,
		private sharedDataService: SharedDataService,
		private router: Router,
		private messageService: MessageService) {

		this.sharedDataService.currentLoggedUser.subscribe(u => {
			this.loggedUser = plainToClass(User, u);
			if (this.loggedUser) {
				this.router.navigateByUrl('/starter');
			}
		});
	}

	ngOnInit() {

	}

	login() {
		this.authService.login(this.logindata).subscribe(data => {
			console.log(data);
			this.router.navigateByUrl(`/`);
		}, ({ error }) => {
			this.messageService.sendError(error, 'Login');
		});
	}

	resetpwd() {
		this.authService.resetPassword(this.email).subscribe(result => {
			this.messageService.sendSuccess(result.msg, 'Reset password');
		}, ({ error }) => {
			this.messageService.sendError(error, 'Reset password');
		});
	}




}
