import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from 'elbuild-ui-lib-core';

import { LoginComponent } from './login/login.component';
import { PublicComponent } from './public/public/public.component';
import { ReservedComponent } from './reserved/reserved/reserved.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'reserved',
    pathMatch: 'full'
  },
  {
    path: 'signin',
    component: LoginComponent

  },
  {
    path: 'reserved',
    loadChildren: () => import('./reserved/reserved.module').then(module => module.ReservedModule),
    component: ReservedComponent,
    canActivate: [AuthenticationGuardService],
    canActivateChild: [AuthenticationGuardService]
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then(module => module.PublicModule),
    component: PublicComponent,

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
