import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'romeDate'
})
export class RomeDatePipe implements PipeTransform {
	transform(value: Date | string | number, format: string = 'dd/MM/yyyy HH:mm'): string {
		if (!value) return null;
		const date = new Date(value);
		return date.toLocaleString('it-IT', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			timeZone: 'Europe/Rome',
			hour12: false
		});
	}
}
