<div class="auth-wrapper">
	<div class="auth-content">
		<div class="container">
			<div class="row mt-5 justify-content-md-center">
				<div class="col-lg-4 col-md-6 mt-5">
					<div class="card">
						<div class="row align-items-center text-center">
							<div class="col-md-12">
								<div class="card-body">
									<form (ngSubmit)="login()">
										<!-- logo da dinamicizzare -->
										<img
											src="assets/images/loghi/sposiamovi.png"
											alt
											class="img-fluid mb-4 logo">
										<h4 class="mb-3 f-w-400">{{'login' | translate}}</h4>
										<div class="input-group mb-3">
											<div class="input-group-prepend">
												<span class="input-group-text">{{'Email' | translate}}</span>
											</div>
											<input
												name="user"
												[(ngModel)]="logindata.username"
												type="email"
												class="form-control"
												placeholder="Email">
										</div>
										<div class="input-group mb-4">
											<div class="input-group-prepend">
												<span class="input-group-text">{{'Password' | translate}}</span>
											</div>
											<input
												name="password"
												[(ngModel)]="logindata.md5password"
												type="password"
												class="form-control"
												placeholder="Password">
										</div>
										<div class="form-group text-left mt-2">
											<div class="checkbox checkbox-primary d-inline">
												<input
													type="checkbox"
													name="checkbox-fill-1"
													id="checkbox-fill-a1"
													checked>
												<label
													for="checkbox-fill-a1"
													class="cr">
													{{'ricorda-accesso' | translate}}
												</label>
											</div>
										</div>
										<button
											type="submit"
											class="btn btn-block btn-primary mb-4">
											{{'login' | translate}}
										</button>
										<p
											class="mb-2 text-muted"
											*ngIf="false">
											{{"password-dimenticata" | translate}}
											<a
												[routerLink]="['/auth/reset-password']"
												class="f-w-400">
												{{"reimposta" | translate}}
											</a>
										</p>

									</form>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
