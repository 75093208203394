<header></header>
<section class="main">

	<router-outlet></router-outlet>

</section>
<footer class="footer">
	<div class="container">
		<app-footer></app-footer>

	</div>
</footer>
