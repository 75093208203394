import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LavoroCliente } from 'jde-ui-lib';

@Component({
  selector: 'app-select-lavoro-modal',
  templateUrl: './select-lavoro-modal.component.html',
  styleUrls: ['./select-lavoro-modal.component.scss']
})
export class SelectLavoroModalComponent implements OnInit {

  lavoriList: LavoroCliente[];
  title = 'lavoro';

  selectedLavoro: number;

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit() {

  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  close(): void {
    const sendLavoro = this.lavoriList.find(a => a.id == this.selectedLavoro)
    console.log(sendLavoro);
    this.activeModal.close(sendLavoro);
  }

  submit() {
    this.close();
  }

}
