<h4>
	<a>
		<span>{{'checklist' | translate}}</span>
	</a>
</h4>

<div class="row px-3 pb-2">
	<div class="col-sm-8">
		<ng-template [ngIf]="!loading && pendingcount === 0">
			<div>
				<p>{{'todo.nopendingtodo' | translate}}</p>
			</div>
		</ng-template>

		<ng-template [ngIf]="!loading && expiredcount > 1">
			<div>
				<p>
					{{'todo.hasexpiredtodos' | translate:{count: expiredcount} }}
				</p>
			</div>
		</ng-template>

		<ng-template [ngIf]="!loading && expiredcount === 1">
			<div>
				<p>
					{{'todo.hasanexpiredtodo' | translate }}
				</p>
			</div>
		</ng-template>
	</div>
	<div class="col-sm-4 text-center">
		<span
			class="text-uppercase cursor-pointer font-weight-bold highlighted-text"
			role="button"
			(click)="navigateToChecklist()">
			{{'click-here' | translate}}
		</span>
		<br>
		<span>{{'see-checklist' | translate}}</span>
	</div>
</div>

<div class="row ml-0 mr-0 mb-1 head">
	<div class="col-2 mt-1">
		<div class="ftd">type</div>
	</div>
	<div class="col-6 mt-1">
		<div class="ftd">details</div>
	</div>
	<div class="mt-1 col-3 text-right">
		<div class="ftd">date</div>
	</div>

	<div class="col-1 mt-1 text-center">
		<div class="ftd"></div>
	</div>

</div>
<div *ngFor="let item of pendinglist; let index = index">
	<div
		*ngIf="pendinglist[index]?.tag != pendinglist[index - 1]?.tag"
		style="font-weight: bold;">
	</div>
	<div
		class="{{item.tododate > now ? 'pending' : 'expired'}}"
		class="row ml-0 mr-0 mb-1">
		<div class="col-2 mt-1">
			<div class="ftd">{{item.tag}}</div>
		</div>
		<div class="col-6 mt-1">
			<div class="ftd">{{item.dettagli}}</div>
		</div>
		<div class="mt-1 col-3 text-right">
			<div class="ftd">{{item.tododate | romeDate }}</div>
		</div>

		<div class="col-1 mt-1 text-center">
			<div class="ftd">
				<span
					class="btn btn-primary"
					(click)="toggleTodo(item)">
					<i class="{{item.dodate ? 'fas fa-check-circle ' : 'far fa-times-circle '}}"></i>
				</span>
			</div>
		</div>

	</div>

</div>
