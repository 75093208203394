import 'reflect-metadata';

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ElbuildCoreComponentsModule, ElbuildUiComponentslibModule } from 'elbuild-ui-componentslib';
import { AuthConfig, BaseAuthModule, ElbuildUiLibModule } from 'elbuild-ui-lib-core';
import { JdeUiLibModule } from 'jde-ui-lib';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';


const authConfig: AuthConfig = {
	cookieprefix: 'jde-ui',
	usertable: 'clienteaccess',
	loginpath: 'cliente/login',
	loginpage: 'signin',
};

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
	],
	imports: [
		CarouselModule,
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		CommonModule,
		FormsModule,
		NgbTooltipModule,
		ElbuildCoreComponentsModule,
		JdeUiLibModule,
		ElbuildUiLibModule.forRoot(authConfig),
		ElbuildUiComponentslibModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			},
			defaultLanguage: 'en'
		}),
		ToastrModule.forRoot({
			preventDuplicates: true
		}),
		NgbModule,
		BaseAuthModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {}
